




import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import { Page } from '@/types/enums'
import { SearchConfigField } from '@/types/utilTypes'
import fieldsObj from '@/views/Job.json'
import Vue from 'vue'

export default Vue.extend({
    name: 'Job',
    components: {
        LookUp
    },
    mounted() {
        this.options.fields = fieldsObj.fields.filter(field => !field.requiredPermission || (field.requiredPermission && this.$store.getters.user.permissions[field.requiredPermission]))
    },  
    data(){
        return {
            options:{
                name: 'jobs',
                api: '/AppraisalJobDetail/GetAppraisalJobDetails',
                exportAPI: {
                    api: '/AppraisalJobDetail/ExportAppraisalJobDetails',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                quantumChartsAPI: {
                    api: '/AppraisalJobDetail/GetAppraisalJobDetailsJson',
                    requiredPermissions: [authPermissions.canAccessAllQuantumCharts]
                },
                showTotal: true,
                fields: [] as SearchConfigField[],
                keywordSearchPlaceholder: 'Enter Job ID or Job Name or Property Address or City or State or Country or Appraiser',
                gmapOptions: {
                    showGmaps: true,
                    exportIdField: 'Job Id',
                    page: Page.JOBS,
                    idFieldKey: 'jobId'
                },
                importables: fieldsObj.importables,
                enableEditor: true
            }
        }
    },
})
